import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineShoppingBag } from "react-icons/md";

import AddToCart from "../AddToCart";
import styles from "./productDetails.module.css";

const MobileMenuBarCart = ({ id }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`${styles.addToCartMobile} md:hidden fixed bottom-0 gap-2 left-0 w-full bg-white text-gray-400 h-16 flex items-center justify-around text-3xl px-4
      `}
    >
      <MdOutlineShoppingBag
        className="text-4 rounded-md xl text-black border-black border p-1 cursor-pointer"
        onClick={() => navigate("/cart")}
      />
      <AddToCart padding={0} id={id} justify={"space-around"} />
    </div>
  );
};

export default MobileMenuBarCart;
