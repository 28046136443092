import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./warehouse.module.css";
import Search from "../../Components/Search";
import BackIcon from "../../Components/BackIcon";
import AddToCart from "../../Components/AddToCart";
import dummyPdtImg from "../../assets/dummyPdtImg.jpeg";
import { getProductsUrl } from "../../apis/constants";
import { UserStates } from "../../context/UserContext";
import { NavbarDefault } from "../../Components/Home/Navbar";
import PaginationBtns from "../../Components/paginationBtns";
import { IoMdCall } from "react-icons/io";

export default function Warehouse() {
  const navigate = useNavigate();
  // const location = useLocation();

  const { accessToken, setCurrentPage, search, setSearch, user } =
    useContext(UserStates);

  const sessionPageNo = sessionStorage.getItem("product-page-no");

  const [products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState("");

  const getProducts = (page) => {
    const config = {
      method: "post",
      url: `${getProductsUrl}${
        page !== undefined ? page : sessionPageNo !== null ? sessionPageNo : "1"
      }`,
      data: { search },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        sessionStorage.setItem("product-page-no", res.data.currentPage);
        setCurrentPage(Number(res.data.currentPage));
        setProducts(res.data.data);
      })
      .catch((err) => {
        setProducts([]);
      });
  };

  useEffect(() => {
    // getCart();
    getProducts(1);
  }, []);

  useEffect(() => {
    getProducts(1);
  }, [search]);

  return (
    <div className="bg-[#F5F5F5] w-screen min-h-screen">
      <NavbarDefault />

      <BackIcon page={"Warehouse"} marginTop={"15px"} marginLeft={"15px"} />

      {user?.isApproved && (user?.type === 7 || user?.type === 4) ? (
        <>
          <Search placeholder={"products"} setSearchKeyword={setSearch} />
          <div className={styles.parentContainer}>
            {products.length === 0 ? (
              <div
                style={{
                  borderRadius: 5,
                  marginBottom: "5px",
                  textAlign: "center",
                  padding: "100px 0",
                  width: "100%",
                }}
              >
                No products
              </div>
            ) : (
              products?.map((product) => (
                <div className={styles.itemContainer}>
                  <div
                    onClick={() => navigate(`${product._id}`)}
                    style={{ marginBottom: "5px" }}
                  >
                    <img
                      src={product.images[0] || dummyPdtImg}
                      alt={`gefmart ${product.name}`}
                      className={styles.productImg}
                    />
                    <h3>{product.name}</h3>
                    <h4 style={{ fontWeight: "500" }}>₹{product?.rate}/- pc</h4>
                  </div>
                  <AddToCart id={product._id} />
                </div>
              ))
            )}
          </div>

          <PaginationBtns
            totalPages={totalPages}
            getItems={getProducts}
            search={search}
            style={{ position: "unset" }}
          />
        </>
      ) : (
        <div className={styles.notAuthorized}>
          <h4 style={{ padding: "0 20px", fontSize: "0.9rem" }}>
            Your channel partner approval is pending,
            {/* <br /> */}
            <span style={{ color: "darkgreen" }}>
              contact us to partner with us
            </span>
          </h4>
          <button
            className={styles.callBtn}
            onClick={() => (window.location.href = `tel:+918921951010`)}
          >
            <IoMdCall />
            <p>Call</p>
          </button>
          <h6
            style={{ color: "darkred", fontSize: "12px", cursor: "pointer" }}
            onClick={() => window.location.reload()}
          >
            Refresh
          </h6>
        </div>
      )}
      {/* <MobileMenuBar /> */}
      {/* <Footer /> */}
    </div>
  );
}
