import axios from "axios";
import dayjs from "dayjs";
import { Image } from "antd";
import "slick-carousel/slick/slick.css";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import "slick-carousel/slick/slick-theme.css";
import { IoLocationOutline } from "react-icons/io5";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdCalendarMonth, MdOutlineStorefront } from "react-icons/md";

import ImageSlider from "../Sliders";
import LoadingIcon from "../LoadingIcon";
import styles from "./marketplace.module.css";
import { NavbarDefault } from "../Home/Navbar";
import { UserStates } from "../../context/UserContext";
import { baseUrl, getAllMarketPlaceListings } from "../../apis/constants";

export default function MarketPlaceProductDetails() {
  const params = useParams();
  const navigate = useNavigate();

  const { accessToken, currentLocation } = useContext(UserStates);

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [marketplaceListings, setMarketplaceListings] = useState([]);

  const getProductDetails = () => {
    const config = {
      method: "get",
      url: `${baseUrl}marketplaces/${params.id}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setProduct(res.data.data);
        setCategoryId(res.data.data.category._id);
        setLoading(false);
      })
      .catch((err) => err);
  };

  const settings = {
    dots: product?.images?.length > 1,
    infinite: product?.images?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: product?.images?.length > 1,
    autoplaySpeed: 3000,
  };

  const getMarketPlaceItems = () => {
    const config = {
      method: "post",
      url: `${getAllMarketPlaceListings}/1`,
      data: {
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
        categoryId,
      },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setMarketplaceListings(res.data.data);
      })
      .catch((err) => {
        setMarketplaceListings([]);
      });
  };

  useEffect(() => {
    getProductDetails();
    setLoading(false);
  }, [params?.id]);

  useEffect(() => {
    getMarketPlaceItems();
  }, [categoryId]);

  return (
    <>
      <NavbarDefault />

      <div
        className="navigationSearch"
        onClick={() => navigate("/marketplace")}
      >
        Search for marketplace products
      </div>

      {loading ? (
        <LoadingIcon />
      ) : (
        <>
          <div className={`${styles.detailsContainer} marketplaceImg`}>
            {product?.images?.length > 1 ? (
              <>
                {/* <Slider {...settings}>
                  {product?.images?.map((item, index) => (
                    <div key={index}>
                      <Image src={item} alt={`${product?.name} - ${index}`} />
                    </div>
                  ))}
                </Slider> */}
                <ImageSlider images={product?.images} alt={product?.name} />
              </>
            ) : (
              <Image
                src={product?.images[0]}
                alt={`${product?.name} gefmart marketplace`}
              />
            )}
            {/* </div> */}
            <div className="md:w-7/12 md:px-6 mt-6">
              <h1 className="text-xl mt-2">{product?.name}</h1>
              <div className="flex gap-1 mt-2 items-center">
                <p>
                  <IoLocationOutline className="text-[#00BF63]" />
                </p>
                <p>
                  {product?.address?.landmark}, {product?.address?.city},{" "}
                  {product?.address?.district}
                </p>
              </div>

              <div className="flex gap-1 mt-2 items-center">
                <MdOutlineStorefront className="text-[#00BF63]" />
                <p>{product?.user?.business_name}</p>
              </div>
              <div className="flex gap-1 mt-2 items-center">
                <MdCalendarMonth className="text-[#00BF63]" />
                <span className=" text-sm text-slate-400">
                  {dayjs(product?.createdAt)?.format("DD-MMM-YYYY hh:mm A")}
                </span>
              </div>

              {product?.hidden_price ? (
                <a
                  href={`https://api.whatsapp.com/send?phone=+91${product?.user?.number}&text=Hello,%20May%20I%know%20the%20price%20of%20the%20product%20${product?.name}%20from%20Gefmart%20Marketplace`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ minWidth: "fit-content", color: "#00BF63" }}
                >
                  <p>* Contact for price</p>
                </a>
              ) : (
                <p className=" text-xl font-medium text-[#00BF63] mt-2">
                  ₹{product?.retail_price}/piece
                </p>
              )}

              <div className={styles.productDetailsContainer}>
                <h4>Product Details</h4>
                <div>
                  <div className={styles.itemAndDetail}>
                    {product?.description?.length > 0 && <p>Description: </p>}
                    <p style={{ marginLeft: "5px" }}>{product?.description}</p>
                  </div>
                  <div className={styles.itemAndDetail}>
                    <p>Brand : </p> <span>{product?.brand?.name}</span>
                  </div>
                  <div className={styles.itemAndDetail}>
                    <p>Category : </p> <span>{product?.category?.name}</span>
                  </div>
                  <div className={styles.itemAndDetail}>
                    <p>About the Seller : </p>{" "}
                    <span>{`${product?.user?.business_name}, ${product?.address?.landmark}, ${product?.address?.state}`}</span>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.actionBtnsContainer} w-full gap-3 mt-4`}
              >
                <a
                  href={`tel:+91${product?.user?.number}`}
                  className="flex items-center bg-[#013220] text-white px-6 rounded-md py-3 gap-2"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <IoMdCall style={{ marginLeft: "-10px" }} />
                  <p>Call</p>
                </a>

                <a
                  // href={`https://api.whatsapp.com/send?phone=+91${product?.user?.number}&text=Hello,%20I%20am%20interested%20in%20buying%20your%20product%20${product?.name}%20from%20Gefmart%20Marketplace`}
                  href={`https://api.whatsapp.com/send?phone=+91${
                    product?.user?.number
                  }&text=${encodeURIComponent(
                    `Hello, I am interested in buying your product ${product?.name} from Gefmart Marketplace https://gefmart.com/marketplace/${product?._id}`
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                  // className="contactBtn"
                  className="flex items-center text-[#013220] border border-[#013220]  bg-white px-12 rounded-md py-3 gap-2"
                  style={{ minWidth: "fit-content" }}
                >
                  <FaWhatsapp />
                  <p>{product?.is_hidden ? "Request Price" : "Chat"}</p>
                </a>
              </div>
              {/* {product?.description && (
                <div className="mt-5 mb-5">
                  <h3 className=" text-lg">Description</h3>
                  <p>{product?.description}</p>
                </div>
              )} */}
            </div>
          </div>

          <h2 style={{ margin: "10px", fontSize: "18px", fontWeight: 600 }}>
            Recommended Products
          </h2>
          <div className={styles.recommendedContainer}>
            {marketplaceListings?.map((item) => (
              <div
                className={`${styles.recommendedItem} w-96 p-3 border shadow-md gap-2 rounded-md cursor-pointer`}
                // className={styles.recommendedItem}
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => navigate(`/marketplace/${item._id}`), 500);
                }}
                key={item._id}
              >
                <div className="flex gap-2">
                  <div className="w-4/12">
                    <img
                      src={item.images[0]}
                      alt="gefmart marketplace item"
                      className="w-44 object-cover"
                      style={{
                        width: "120px",
                        height: "120px",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  <div style={{ maxWidth: "68%" }}>
                    <div className="flex w-full justify-between gap-4 mb-1">
                      <h1
                        className={`${styles.itemName} text-[#013220] text-md mr-1`}
                      >
                        {item.name}
                      </h1>
                    </div>
                    {item.address?.city !== "null" &&
                      item.address?.district !== "" && (
                        <div className="flex gap-1">
                          <p>
                            <IoLocationOutline className="text-[#00BF63]" />
                          </p>
                          <p className={`${styles.address} text-sm`}>{`${
                            item.address?.city === "null"
                              ? ""
                              : `${item.address?.city},`
                          } ${item.address?.district}`}</p>
                        </div>
                      )}

                    <div className="flex gap-1">
                      <MdOutlineStorefront className="text-[#00BF63]" />
                      <p className="text-sm">{item.user.business_name}</p>
                    </div>
                    {item.hidden_price ? (
                      <p className="text-sm font-medium text-[#00BF63] mt-2">
                        * contact for price
                      </p>
                    ) : (
                      <p className="text-lg font-medium text-[#00BF63] mt-2">
                        ₹{item?.retail_price || item?.wholesale_price}/pc
                      </p>
                    )}
                    <span className="text-sm text-slate-400">
                      {dayjs(item.createdAt).format("DD-MMM-YYYY hh:mm A")}
                    </span>
                  </div>
                </div>
                <div className="flex w-full justify-between mt-2">
                  <div className="flex items-center bg-[#013220] text-white px-9 rounded-md py-1 gap-2">
                    <a
                      href={`tel:+91${item.user.number}`}
                      className="contactBtn"
                    >
                      <IoMdCall />
                      <p style={{ marginLeft: "5px" }}>Call</p>
                    </a>
                  </div>
                  <div
                    className="flex items-center text-[#013220] border border-[#013220] bg-white rounded-md py-1 justify-center"
                    style={{ width: "60%" }}
                  >
                    <a
                      // href={`https://api.whatsapp.com/send?phone=+91${item.user.number}&text=Hello,%20I%20am%20interested%20in%20buying%20your%20product%20${item.name}%20from%20Gefmart%20Marketplace`}
                      href={`https://api.whatsapp.com/send?phone=+91${
                        product?.user?.number
                      }&text=${encodeURIComponent(
                        `Hello, I am interested in buying your product ${product?.name} from Gefmart Marketplace https://gefmart.com/marketplace/${product?._id}`
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                      className="contactBtn"
                    >
                      <FaWhatsapp />
                      <p style={{ marginLeft: "5px" }}>
                        {item.is_hidden ? "Request Price" : "Chat"}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
