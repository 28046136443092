import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
  Typography,
  IconButton,
  Box,
  ClickAwayListener,
} from "@mui/material";
import {
  Home,
  Store,
  Category,
  Sell,
  Help,
  Share,
  Info,
  Edit,
  Logout,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const drawerWidth = 300;

export default function ProfileSider({ setShowSider }) {
  const redirectToOSUrl = () => {
    // Define URLs for different OS
    const iosUrl = "https://apps.apple.com/ph/app/gefmart/id6502963364";
    const androidUrl =
      "https://play.google.com/store/apps/details?id=com.gefmart.app&pcampaignid=web_share";

    // Detect the user's OS
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Redirect to Android URL
      window.location.href = androidUrl;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to iOS URL
      window.location.href = iosUrl;
    } else {
      // Redirect to a default URL if OS is not recognized
      window.location.href = "/";
    }
  };

  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", float: "right" }}>
      <ClickAwayListener onClickAway={() => setShowSider(false)}>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              left: "unset",
              right: 0,
              float: "right",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 2,
              mb: 2,
            }}
          >
            <Avatar
              src={
                localStorage.getItem("profile-pic") ||
                "https://via.placeholder.com/150"
              }
              sx={{ width: 100, height: 100 }}
            />
            <IconButton
              sx={{
                position: "absolute",
                top: 70,
                right: 110,
                bgcolor: "white",
                boxShadow: 1,
              }}
              onClick={() => {
                setShowSider(false);
                navigate("/profile");
              }}
            >
              <Edit />
            </IconButton>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {localStorage.getItem("user-name") || ""}
            </Typography>
          </Box>
          <Divider />
          <List>
            <ListItem
              button
              onClick={() => {
                setShowSider(false);
                navigate("/");
              }}
            >
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setShowSider(false);
                navigate("/warehouse");
              }}
            >
              <ListItemIcon>
                <Store />
              </ListItemIcon>
              <ListItemText primary="Gefmart Warehouse" />
            </ListItem>
            {/* <ListItem button onClick={() => {setShowSider(false);navigate("/orders")}}>
              <ListItemIcon>
                <ShoppingCart />
              </ListItemIcon>
              <ListItemText primary="My Orders" />
            </ListItem> */}
            <ListItem
              button
              onClick={() => {
                setShowSider(false);
                navigate("/categories");
              }}
            >
              <ListItemIcon>
                <Category />
              </ListItemIcon>
              <ListItemText primary="View all Categories" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setShowSider(false);
                navigate("/marketplace");
              }}
            >
              <ListItemIcon>
                <Sell />
              </ListItemIcon>
              <ListItemText primary="Sell on Gefmart" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              onClick={() => {
                setShowSider(false);
                navigate("/help");
              }}
            >
              <ListItemIcon>
                <Help />
              </ListItemIcon>
              <ListItemText primary="Help & Support" />
            </ListItem>
            <ListItem button onClick={redirectToOSUrl}>
              <ListItemIcon>
                <Share />
              </ListItemIcon>
              <ListItemText primary="Download App" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setShowSider(false);
                navigate("/about");
              }}
            >
              <ListItemIcon>
                <Info />
              </ListItemIcon>
              <ListItemText primary="About GEFMART" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setShowSider(false);
                navigate("/login");
                Cookies.remove("gefmartUserToken");
              }}
            >
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
      </ClickAwayListener>
      <main style={{ flexGrow: 1, padding: "16px" }}>
        <Typography variant="h4">Main Content Area</Typography>
        <Typography>
          This is where the main content of the app will be displayed.
        </Typography>
      </main>
    </div>
  );
}
