import React from "react";

import MarketHeader from "../../Components/MarketPlace/MarketHeader";
import Footer from "../../Components/Home/Footer";
import { NavbarDefault } from "../../Components/Home/Navbar";
import GoBack from "../../Components/GoBack";
import Search from "../../Components/Search";

const MarketPlace = () => {
  return (
    <div className="bg-[#F5F5F5] w-screen">
      <NavbarDefault />
      <GoBack title={"Marketplace"} />
      <Search placeholder={"marketplace"} />
      <div className="px-3">
        {/* <div className="flex gap-5 items-center md:py-7 py-3">
          <BackIcon />
          <h1 className="text-xl">Market Place</h1>
        </div> */}
        <MarketHeader />
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default MarketPlace;
