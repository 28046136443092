import { useEffect, useState } from "react";
import styles from "./sliders.module.css";

export default function ImageSlider({ images, alt }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  console.log("currentIndex", currentIndex);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? images.length - 1
        : (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [images.length]);

  return (
    <div className={styles.container}>
      <button className={`${styles.btn} ${styles.left}`} onClick={prevSlide}>
        ❮
      </button>
      <div className={styles.wrapper}>
        {images.map((image, index) => (
          <>
            <div
              className={`${styles.slide} ${
                index === currentIndex ? styles.show : ""
              }`}
              key={index}
              style={{
                transform: `translateX(${(index - currentIndex) * 100}%)`,
              }}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                className={styles.image}
              />
            </div>

            {/* <div
              className={styles.slide}
              key={index}
              style={{
                transform: `translateX(${(index - currentIndex) * 100}%)`,
                display: index === currentIndex ? "block" : "none",
              }}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                className={styles.image}
              />
            </div> */}
          </>
        ))}
      </div>
      <button className={`${styles.btn} ${styles.right}`} onClick={nextSlide}>
        ❯
      </button>
    </div>
  );
}
