import BackIcon from "../../Components/BackIcon";
import Footer from "../../Components/Home/Footer";
import { NavbarDefault } from "../../Components/Home/Navbar";
import { primaryColor } from "../../constants/constants";

export default function About() {
  return (
    <>
      <NavbarDefault />
      <div style={{ padding: "20px" }}>
        <BackIcon page={"About"} />
        <div style={{ marginTop: "10px" }}>
          <h1 style={{ fontWeight: 600, fontSize: "20px" }}>About Gefmart</h1>
          <p style={{ marginTop: "15px" }}>
            Welcome to Gefmart, Kerala’s premier B2B app and marketplace for the
            construction sector! Designed exclusively for businesses in the
            electrical and construction industries, Gefmart connects you to a
            world of convenience, quality, and competitive pricing. <br />
            <br /> With Gefmart, you can: <br />
            <br /> Access a warehouse group buying platform to source
            high-quality electrical products at unbeatable rates.
            <br />
            <br /> Explore a dedicated marketplace tailored to meet the unique
            needs of construction sector businesses.
            <br />
            <br /> Our app empowers channel partners and businesses to
            streamline their procurement process, save costs, and grow their
            operations. At Gefmart, we’re not just about transactions—we’re
            about “Generating Empowered Franchises.”
            <br />
            <br /> Join the Gefmart network today and unlock new opportunities
            to expand your electrical business across Kerala!
          </p>
        </div>

        {/* <h4>
          <span style={{ color: primaryColor }}>Developed by</span> : Codescap
        </h4>
        <h4>
          <span style={{ color: primaryColor }}>Manufactured by</span> : Geften
          Electrical Solutions
        </h4> */}
      </div>
      {/* <Footer /> */}
    </>
  );
}
