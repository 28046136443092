import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

import { getAllBrandsUrl } from "../../apis/constants";
import styles from "./brands.module.css";
import { NavbarDefault } from "../../Components/Home/Navbar";
import { UserStates } from "../../context/UserContext";
import Footer from "../../Components/Home/Footer";

export default function Brands() {
  const accessToken = Cookies.get("gefmartUserToken");

  const { search } = useContext(UserStates);

  const navigate = useNavigate();

  const [brands, setBrands] = useState([]);

  const getAllBrands = () => {
    const config = {
      method: "post",
      url: getAllBrandsUrl,
      data: { search: search },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setBrands(res.data.data))
      .catch((err) => err);
  };

  useEffect(() => {
    getAllBrands();
  }, [search]);

  return (
    <>
      <NavbarDefault getItems={getAllBrands} />
      <div className={styles.container}>
        {brands.map((item, index) => (
          <div
            className={styles.singleItem}
            key={index}
            onClick={() =>
              navigate({ pathname: "/products" }, { state: item.name })
            }
          >
            <div className="h-28 w-28 cursor-pointer">
              <img
                className="object-cover w-full rounded-lg m-auto"
                src={item?.image}
                alt={`Gefmart brand ${item?.name}`}
              />
            </div>
            <h3 className="text-log mt-1" style={{ fontWeight: 600 }}>
              {item.name}
            </h3>
          </div>
        ))}
      </div>
      {/* <Footer /> */}
    </>
  );
}
