import axios from "axios";
import { Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import BackIcon from "../../Components/BackIcon";
import { getProductById } from "../../apis/products";
import { getProductsUrl } from "../../apis/constants";
import { UserStates } from "../../context/UserContext";
import dummyPdtImage from "../../assets/dummyPdtImg.jpeg";
import ProductContext from "../../utils/contexts/ProductContext";
import MobileMenuBarCart from "../../Components/ProductDetail/MenuBarCart";
import HeadDescription from "../../Components/ProductDetail/HeadDescription";

import styles from "./productdetails.module.css";
import { NavbarDefault } from "../../Components/Home/Navbar";
import LoadingIcon from "../../Components/LoadingIcon";

function ProductDetail() {
  const params = useParams();
  const navigate = useNavigate();

  const { accessToken } = useContext(UserStates);

  const [data, setData] = useState();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getProducts = (data) => {
    const config = {
      method: "post",
      url: `${getProductsUrl}1`,
      data: { search: data?.item_name?.slice(0, 5) },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setProducts(res.data.data))
      .catch((err) => setProducts([]));
  };

  useEffect(() => {
    setLoading(false);
    async function fetch() {
      const response = await getProductById(params.id);
      setData(response?.data?.data);
      getProducts(response?.data?.data);
    }
    fetch();
  }, [params]);

  return (
    <div>
      {/* <div className="w-screen shadow-lg"> */}
      <NavbarDefault />
      {/* </div> */}

      {loading ? (
        <LoadingIcon />
      ) : (
        <>
          <div style={{ padding: "0 10px 10px" }}>
            <BackIcon page={"Products"} marginTop={"0"} />
            <div
              className="navigationSearch"
              onClick={() => navigate("/warehouse")}
              style={{ margin: "10px auto", width: "100%" }}
            >
              Search for products
            </div>
          </div>
          <ProductContext.Provider value={data}>
            <div
              className={`${styles.productContainer} md:px-6 px-2 +relative"`}
            >
              <HeadDescription />

              <div>
                <h2
                  style={{
                    margin: "10px 0 10px",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Similar Products
                </h2>
                <div style={{ display: "flex", overflow: "scroll" }}>
                  {products?.map((item) => (
                    <div
                      key={item._id}
                      className={`${styles.itemContainer} border shadow-md gap-2 rounded-md cursor-pointer`}
                      onClick={() => {
                        setLoading(true);
                        setTimeout(
                          () => navigate(`/warehouse/${item._id}`),
                          500
                        );
                      }}
                    >
                      <img
                        src={item.images[0] || dummyPdtImage}
                        className={styles.image}
                      />
                      <h6>{item.name}</h6>
                      <span>Rate ₹{item?.rate}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* <Footer /> */}
            <MobileMenuBarCart id={data?._id} />
          </ProductContext.Provider>
        </>
      )}
    </div>
  );
}

export default ProductDetail;
