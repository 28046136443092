import loadingIcon from "../../assets/loading2.gif";

export default function LoadingIcon() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        background: "white",
        zIndex: 9999,
      }}
    >
      <img
        src={loadingIcon}
        alt="gefmart loading"
        style={{ margin: "46vh auto", width: "70px", height: "auto" }}
      />
    </div>
  );
}
