import dayjs from "dayjs";
import React from "react";

const TimeLine = ({ items }) => {
  return (
    <div>
      {items?.map((item) => (
        <ol className="relative border-s border-[#00BF63]  text-[#00BF63]">
          <div className="absolute w-3 h-3 bg-[#00BF63] rounded-full mt-0 -start-1.5 border border-[#00BF63]" />
          <li className="ms-4">
            <time className="mb-1 text-base font-normal leading-none  ">
              {item.status}
            </time>
            <p className="text-sm font-normal text-gray-500 ">
              {dayjs(item.date).format("ddd-MM-YYYY hh:mm a")}
            </p>
          </li>
          {(item.status === "Cancelled" || item.status === "Completed") && (
            <div
              className="absolute w-3 h-3 rounded-full mt-0 -start-1.5 border"
              style={{
                background:
                  item.status === "Cancelled" ? "lightgrey" : "#00BF63",
                border: item.status === "Cancelled" ? "lightgrey" : "#00BF63",
              }}
            />
          )}
        </ol>
      ))}
    </div>
  );
};

export default TimeLine;
