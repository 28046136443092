import {
  CallOutlined,
  LocationOnOutlined,
  MailOutline,
} from "@mui/icons-material";

import styles from "./help.module.css";
import BackIcon from "../../Components/BackIcon";

export default function Help() {
  return (
    <div style={{ padding: "25px" }}>
      <BackIcon page={"Help & Support"} />
      <div>
        <div className={styles.container}>
          <div className={styles.headingContainer}>
            <LocationOnOutlined className={styles.icon} />
            <span style={{ margin: "10px auto" }}>Locate Us</span>
          </div>
          Geften Electrical Solutions LLP 39/2475-B1, Suite 716, LR Towers,
          SJRRA 104, South Janatha Road, Palarivattom PO, Kochi Ernamkulam
          (Dist) Kerala-682025
        </div>
        <div className={styles.container}>
          <div className={styles.headingContainer}>
            <MailOutline className={styles.icon} />
            <span style={{ margin: "10px auto" }}>Mail Us</span>
          </div>
          Mail Us: gefmartsupport@geften.com
        </div>
        <div className={styles.container}>
          <div className={styles.headingContainer}>
            <CallOutlined className={styles.icon} />
            <span style={{ margin: "10px auto" }}>Call or Chat</span>
          </div>
          +91 8921951010 <br />
          9.00am to 7.00pm (Mon-Sat)
        </div>
      </div>
    </div>
  );
}
