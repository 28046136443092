const primaryColor = "#03bf62";
const secondaryColor = "#013220";
const dummyPdtImg = "../assets/dummyPdtImg.jpeg";

const isValidUrl = (url) => {
  try {
    new URL(url); // This will throw an error if the string is not a valid URL.
    return true;
  } catch {
    return false;
  }
};

export { primaryColor, secondaryColor, dummyPdtImg, isValidUrl };
