import axios from "axios";
import { baseUrl, cartUrl } from "../../apis/constants";
import { useContext, useEffect, useState } from "react";
import { UserStates } from "../../context/UserContext";
import { MinusIcon, PlusIcon } from "@heroicons/react/16/solid";
import { message } from "antd";

export default function AddToCart({ id, padding, justify, width }) {
  const { accessToken, userId, cart, setCart } = useContext(UserStates);

  const [filteredItem, setFilteredItem] = useState("");

  const styles = {
    btnStyles: {
      background: "#013220",
      padding: padding !== undefined ? padding : "10px 20px",
      borderRadius: "5px",
      color: "white",
      fontWeight: "500",
      width: width !== undefined ? width : "100%",
      display: "flex",
      justifyContent: justify !== undefined ? justify : "space-between",
      alignItems: "center",
      fontSize: "13px",
      minWidth: "max-content",
    },
    svg: {
      width: "15px",
      height: "15px",
      cursor: "pointer",
    },
  };

  // Customize the style
  message.config({
    top: "85%", // Position from the top
    duration: 2, // Duration in seconds
    style: {
      transform: "translateY(-50%)", // Center vertically
      textAlign: "center", // Optional: Center the text
    },
  });

  const addToCart = (type) => {
    const config = {
      method: "post",
      url: cartUrl,
      data: {
        productId: id,
        userId,
        quantity: type === "plus" ? 1 : -1,
      },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setCart(res.data.data);
        message.success("Item successfully added to cart");
      })
      .catch((err) => err);
  };

  useEffect(() => {
    const item = cart?.find((item) => item.product._id === id);
    setFilteredItem(item);
  }, [id, cart]);

  return (
    <div
      style={styles.btnStyles}
      onClick={() => {
        if (!(filteredItem?.quantity > 0)) {
          addToCart("plus");
        }
      }}
    >
      {filteredItem?.quantity > 0 ? (
        <>
          <MinusIcon style={styles.svg} onClick={() => addToCart("minus")} />
          {filteredItem.quantity}
          <PlusIcon style={styles.svg} onClick={() => addToCart("plus")} />
        </>
      ) : (
        <p
          style={{ margin: "auto", cursor: "pointer" }}
          // onClick={() => addToCart("plus")}
        >
          Add to cart
        </p>
      )}
    </div>
  );
}
