// import React from "react";
// import ReactDOM from "react-dom/client";
// import App from "./App";
// import "./index.css";
// import { BrowserRouter } from "react-router-dom";
// import UserContext from "./context/UserContext";
// import MobileMenuBar from "./Components/Home/MobileMenuBar";

// const root = ReactDOM.createRoot(document.getElementById("root"));

// const location = window.location.pathname;

// console.log("location", location);

// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <UserContext>
//         <div className="allScreensMarginBottom">
//           <App />
//         </div>
//         {!location.includes("login") && <MobileMenuBar />}
//       </UserContext>
//     </BrowserRouter>
//   </React.StrictMode>
// );

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter, useLocation } from "react-router-dom";
import UserContext from "./context/UserContext";
import MobileMenuBar from "./Components/Home/MobileMenuBar";
import Footer from "./Components/Home/Footer";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Wrapper component to handle location updates
const AppWithLocation = () => {
  const location = useLocation();

  return (
    <>
      <div className="allScreensMarginBottom">
        <App />
      </div>
      {!location.pathname.includes("login") && <MobileMenuBar />}
      <Footer />
    </>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserContext>
        <AppWithLocation />
      </UserContext>
    </BrowserRouter>
  </React.StrictMode>
);
