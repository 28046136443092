import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";

import {
  getAllCategoriesUrl,
  getAllMarketPlaceListings,
} from "../../apis/constants";
import styles from "./categories.module.css";
import { UserStates } from "../../context/UserContext";
import { NavbarDefault } from "../../Components/Home/Navbar";
import PaginationBtns from "../../Components/paginationBtns";
import MobileSearch from "../../Components/Search/mobileSearch";

const Categories = () => {
  const accessToken = Cookies.get("gefmartUserToken");

  const { search, setCurrentPage } = useContext(UserStates);

  const [curr, setCurr] = useState();
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const getCategories = () => {
    const config = {
      method: "post",
      // url: marketPlaceByCategoryUrl,
      url: getAllCategoriesUrl,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setData(res.data.data);
        setCurr(res.data.data[0]?._id);
      })
      .catch((err) => console.log(err.message));
  };

  const getItemsByCategory = (page) => {
    const config = {
      method: "post",
      url: `${getAllMarketPlaceListings}/${page}`,
      data: { categoryId: curr, search },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setCurrentPage(Number(res.data.currentPage));
        setItems(res.data.data);
      })
      .catch((err) => setItems([]));
  };

  useEffect(() => {
    getCategories();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (curr !== undefined) {
      getItemsByCategory(1);
    }
  }, [curr]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (debouncedSearch.length > 3 || search.length === 0) {
      getItemsByCategory(1);
    }
  }, [debouncedSearch]);

  return (
    <div className="bg-[#F5F5F5] w-screen">
      <NavbarDefault />

      <MobileSearch title={"marketplace"} />

      <div className="md:px-10 px-2 pt-2">
        <div className={`${styles.container} flex w-full`}>
          <div className="w-1/4 py-3">
            <div
              className="flex flex-col w-full h-full overflow-scroll lg:overflow-x-scroll items-center"
              style={{ height: "100vh" }}
            >
              {data?.map((item, index) => (
                <div
                  onClick={() => setCurr(item?._id)}
                  className={`flex max-w-28 flex-col justify-center items-center mb-5 text-center md:pr-10 w-full border-r-2 ${
                    curr === item?._id && "border-r-2 border-[#013220]"
                  }`}
                  key={index}
                >
                  <img
                    className="rounded-full max-w-16 h-16 object-cover lg:max-w-28 lg:w-28 lg:h-28"
                    src={item?.image}
                    alt="category img"
                  />
                  <h3 className="mt-2 text-xs lg:text-sm text w-24">
                    {item?.name}
                  </h3>
                </div>
              ))}
            </div>
          </div>
          {items?.length > 0 ? (
            <div
              className={`w-3/4 grid md:grid-cols-3 grid-cols-2 align-top h-full gap-3 md:gap-5 mr-2 md:mr-0 ${styles.categoryItemListing}`}
              style={{ height: "100vh", overflowY: "scroll" }}
            >
              {items.map((item) => (
                <div
                  className={`${styles.singleItem} border shadow-md p-2 max-w-52 md:h-54`}
                >
                  <Link to={`/marketplace/${item?._id}`}>
                    <div className="border">
                      <img
                        src={item.images[0]}
                        alt="gefmart marketplace item"
                        className="w-full md:h-36 h-28 object-contain"
                      />
                    </div>
                    <p className={`${styles.itemName} w-full text-center`}>
                      {item.name}
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.noItemsContainer}>
              No items found for this category
            </div>
          )}
        </div>
      </div>

      <PaginationBtns
        totalPages={totalPages}
        getItems={getItemsByCategory}
        search={search}
        style={{ position: "unset" }}
      />
      {/* <Footer /> */}
    </div>
  );
};

export default Categories;
