import { message } from "antd";
import Api from "../utils/Api";
import { userEndpoints } from "../utils/endpoints/user";
import { notifyError } from "../utils/notification";

export const sendOTP = async (phone) => {
  // Customize the style
  message.config({
    top: "85%", // Position from the top
    duration: 2, // Duration in seconds
    style: {
      transform: "translateY(-50%)", // Center vertically
      textAlign: "center", // Optional: Center the text
    },
  });

  try {
    const res = await Api.post(userEndpoints.sendOTP, { phone });
    message.success("OTP Sent Successfully");
    return res;
  } catch (err) {
    notifyError(err.response.data.message);
    return;
  }
};

export const verifyOTP = async (phone, otp) => {
  try {
    const res = await Api.post(userEndpoints.verifyOTP, { phone, otp });
    return res;
  } catch (err) {
    notifyError(err.response.data.message);
    return;
  }
};

export const userRegister = async (user) => {
  try {
    const res = await Api.post(userEndpoints.register, user);
    return res;
  } catch (err) {
    console.log(err);
    notifyError(err.response.data.message);
    return;
  }
};
