import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { NavbarDefault } from "../../Components/Home/Navbar";
import styles from "./blogs.module.css";
import Search from "../../Components/Search";
import { getAllBlogsUrl } from "../../apis/constants";
import { UserStates } from "../../context/UserContext";
import axios from "axios";
import GoBack from "../../Components/GoBack";
import BackIcon from "../../Components/BackIcon";

const Blogs = () => {
  const { search, accessToken } = useContext(UserStates);

  const [pageNo, setPageNo] = useState(1);
  const [localSearch, setLocalSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [blogs, setBlogs] = useState([]);

  const getBlogs = (page) => {
    const config = {
      method: "post",
      // url: `${getAllBlogsUrl}/${page}`,
      url: getAllBlogsUrl,
      data: { search: search || localSearch },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        // setBlogs([...blogs, ...res.data.data]);
        setBlogs(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setBlogs([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    // Function to check if the user has reached the bottom of the page
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 10 &&
        !loading &&
        totalPages !== pageNo
      ) {
        setPageNo((prevPageNo) => {
          setLoading(true);
          const nextPage = prevPageNo + 1;
          getBlogs(nextPage);
          return nextPage;
        });
      }
    };

    // Add event listener for scrolling
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [blogs, loading]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (debouncedSearch.length > 3 || search.length === 0) {
      getBlogs(1);
    }
  }, [debouncedSearch]);

  return (
    <div style={{ background: "#F5F5F5" }}>
      <NavbarDefault />
      <div className={styles.head}>
        <BackIcon
          page={"Blogs & News"}
          marginTop={"15px"}
          marginLeft={"15px"}
        />
        <Search
          placeholder={"blogs"}
          style={{ width: "400px" }}
          setSearchKeyword={setLocalSearch}
        />
      </div>
      {/* <div className={styles.firstBlogContainer} style={{ marginTop: "10px" }}>
        <div className={styles.contentWrapper}>
          <img src={blogs[0]?.image} alt={`gefmart blog ${blogs[0]?.title}`} />
          <h4>{blogs[0]?.title}</h4>

          <Link className={styles.readMoreBtn} to={`/blog/${blogs[0]?._id}`}>
            Read more
          </Link>
        </div>
      </div> */}
      {blogs.length > 0 ? (
        <div className={styles.smallTilesContainer}>
          {blogs?.map((item) => (
            <div
              className="!justify-center items-center"
              style={{ width: "100%" }}
            >
              <div className={styles.smallTile}>
                <div className="md:h-3/4 w-full">
                  <img
                    className="object-cover w-full h-full rounded-t-md"
                    src={item?.image}
                    alt={`gefmart blog ${item?.title}`}
                  />
                </div>
                <h4 className={styles.title}>{item?.title}</h4>
                <Link
                  className={styles.readMoreBtn}
                  to={`/blog/${item._id}`}
                >
                  Read more
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            width: "100%",
            padding: "40px",
            background: "#f5f5f5",
          }}
        >
          No blogs found for this search
        </div>
      )}
    </div>
  );
};

export default Blogs;
