import { useNavigate } from "react-router-dom";

export default function MobileSearch({ title }) {
  const navigate = useNavigate();

  return (
    <div className="navigationSearch" onClick={() => navigate("/marketplace")}>
      Search for marketplace products
    </div>
  );
}
