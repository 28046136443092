import React, { useState, useEffect, useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import SliderComponent from "./Slider";
import styles from "./home.module.css";
import { getBanner } from "../../apis/Brands";
import Latest from "../../Components/Home/Latest";
import Brands from "../../Components/Home/Brands";
import SearchBar from "../../Components/UI/SearchBar";
import { UserStates } from "../../context/UserContext";
import BlogsNews from "../../Components/Home/BlogsNews";
import Categories from "../../Components/Home/Categories";
import { NavbarDefault } from "../../Components/Home/Navbar";

const Home = () => {
  const { setSearch } = useContext(UserStates);

  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      async function fetch() {
        const response = await getBanner();
        setData(response.data.data);
      }
      fetch();
    } catch (error) {
      console.log(error);
    }

    setSearch("");
  }, []);

  return (
    <div className=" relative min-h-screen overflow-hidden">
      <div className="w-screen shadow-lg">
        <NavbarDefault />
      </div>

      <div className={styles.quickLinks}>
        <Link to={`/about`}>About</Link>
        <Link to={`/warehouse`}>Gefmart Warehouse</Link>
        <Link to="/categories">Categories</Link>
        <Link to="/brands">Top Brands</Link>
        {/* <Link to="/marketplace/add">Sell on Gefmart</Link> */}
        <Link to="/marketplace">Marketplace</Link>
      </div>

      <div className="w-screen mt-3">
        <SliderComponent slides={data} />

        <div className="lg:hidden mt-8">
          <SearchBar />
        </div>
        <div className="w-screen px-4 lg:px-12 py-4 lg:py-6 flex flex-col gap-4">
          <Categories />
          <Brands />
          <Latest />
        </div>
        <BlogsNews />
      </div>
      {/* <MobileMenuBar /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
