import React, { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";

import { getAllBlogs } from "../../apis/Blog";
import styles from "./blog.module.css";
import { useNavigate } from "react-router-dom";

const BlogSideBar = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      async function fetch() {
        const response = await getAllBlogs();
        setData(response.data.data);
      }
      fetch();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="w-full px-3 flex flex-col gap-2">
      {data.map(
        (blog, index) =>
          index !== 0 && (
            <div
              className="flex w-full gap-2 bg-white p-3 cursor-pointer"
              onClick={() => navigate(`/blog/${blog._id}`)}
            >
              <div className="w-1/2">
                <img
                  src={blog.image}
                  alt={`${blog.title}`}
                  className={styles.blogSiderImg}
                />
              </div>
              <div className="w-1/2 flex flex-col justify-between">
                <h1>{blog.title}</h1>
                <div className="flex justify-between">
                  <p className=" text-gray-400">Jan 24, 2024</p>
                  <FaArrowRightLong className="text-[#00BF63]" />
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default BlogSideBar;
