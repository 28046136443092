import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { TiTick } from "react-icons/ti";
import Cookies from "js-cookie";

import { notifySuccess } from "../../utils/notification";
import { sendOTP, verifyOTP } from "../../apis/user";
import styles from "./login.module.css";
import { accessToken } from "../../apis/constants";
import { UserStates } from "../../context/UserContext";

function Login() {
  const navigate = useNavigate();

  const { setAccessToken } = useContext(UserStates);

  const [focusedIndex, setFocusedIndex] = useState("");
  const inputs = useRef([]);
  const [error, setError] = useState({
    error: "",
    message: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [timer, setTimer] = useState(60);
  const [otpSent, setOtpSent] = useState(false);

  //to send the otp
  const sendOtp = async () => {
    if (timer === 0 || timer === 60) {
      if (!phoneNumber.match("[0-9]{10}")) {
        setError({
          error: "number",
          message: "Enter valid 10 digit mobile number",
        });
        return;
      } else {
        const data = await sendOTP(phoneNumber);
        console.log("data returned from sent otp", data);
        if (data && data.data.message) {
          notifySuccess(data.data.message);
          setOtpSent(true);
        }
      }
    }
  };

  //to handle the otp entering
  const handleChange = (index, e) => {
    const value = e.target.value;
    if (value.length === 1 && index < inputs.current.length - 1) {
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !e.target.value) {
      inputs.current[index - 1].focus();
    }

    const enteredOtp = inputs.current.map((input) => input.value).join("");

    if (
      e.key === "Enter" &&
      enteredOtp?.length === 4 &&
      phoneNumber.length === 10
    ) {
      verifyOtp();
    }
  };

  // verify otp
  const verifyOtp = async () => {
    if (!phoneNumber.match("[0-9]{10}")) {
      setError({
        error: "number",
        message: "Enter valid 10 digit mobile number",
      });
      return;
    }

    const enteredOtp = inputs.current.map((input) => input.value).join("");
    const data = await verifyOTP(phoneNumber, enteredOtp);

    const res = data.data;

    if (data && res.newUser) {
      // navigate("/signup", { state: phoneNumber });
      alert(
        "New user registrations are only allowed in mobile app, please download the app from corresponding store"
      );
      // Check the user's operating system
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        // Redirect to Play Store
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.gefmart.app";
      } else if (
        /iPad|iPhone|iPod|Macintosh/.test(userAgent) &&
        !window.MSStream
      ) {
        // Redirect to App Store
        window.location.href =
          "https://apps.apple.com/ph/app/gefmart/id6502963364";
      } else {
        // Show an alert for unsupported devices
        alert("Please use a mobile device to download the app.");
      }
    } else if (data) {
      Cookies.set("gefmartUserToken", `Bearer ${res.token}`, {
        expires: 36500,
      });
      setAccessToken(`Bearer ${res.token}`);
      localStorage.setItem("userId", res.data._id);
      localStorage.setItem("user-name", res.data.name);
      localStorage.setItem("profile-pic", res.data.image);
      navigate("/");
    }
  };

  //hook to decrement the timer every second
  useEffect(() => {
    if (timer > 0 && otpSent) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer, otpSent]);

  useEffect(() => {
    if (accessToken) {
      navigate("/");
    }
    setOtpSent(false);
  }, []);

  useEffect(() => {
    if (phoneNumber?.length === 10) {
      sendOTP(phoneNumber);
      setOtpSent(true);
    }
  }, [phoneNumber]);

  let otps = inputs.current.map((input) => input.value).join("")?.length;

  useEffect(() => {
    if (otps === 4 && phoneNumber.length === 10) {
      verifyOtp();
    }
  }, [otps, phoneNumber]);

  return (
    <div
      className="bg-[#013220] h-screen overflow-hidden"
      style={{ overflowY: "scroll" }}
    >
      <Toaster />
      <div className="login absolute h-full w-full z-0 overflow-hidden"></div>
      <div className=" backdrop-blur-ss xm:max-w-screen min-h-screen text-white z-1 relative pb-10">
        {/* <img className='absolute top-0 -rotate-26 blur-sm z-0' src={'/loginbg.png'} /> */}
        <div className=" md:ml-[80px] lg:ml-[100px] xl:ml-[140px] ">
          <div className="hidden md:flex pt-[70px]">
            {/* <div className="h-[50px] w-[50px] bg-[#00BF63] rounded-md"></div>
            <div className="h-[50px] w-[50px] border-2 border-white rounded-md mx-5"></div> */}
          </div>

          <div className="xm:flex  xm:w-[900px] lg:w-[1114px] lg:h-[399px] md:mt-20 lg:mt-24">
            <div className="md:flex flex flex-col justify-around  h-full md:flex-row">
              <div className="mr-24 lg:mr-32 mb-5">
                <h1 className="hidden md:block text-xl font-medium text-[24px] lg:text-[34px]">
                  Welcome to Gefmart
                </h1>
                <p className="hidden md:block text-[#B0C2BB] leading-[30px] mt-8 lg:mt-12 text-[16px] lg:text-[18px]">
                  Join the Gefmart network and take your electrical business to
                  the next level! Exclusively designed for construction sector
                  businesses in Kerala, the app offers: <br />
                  •⁠ ⁠Seamless access to the Gefmart warehouse for group buying.{" "}
                  <br />
                  •⁠ ⁠A dedicated marketplace for unmatched deals and
                  opportunities.
                </p>
                <p className=" text-[#B0C2BB] leading-[30px]  lg:mt-12 text-[16px] lg:text-[18px] flx item flex-col justify-center w-screen md:w-full p-10 md:p-0 md:block text-wrap">
                  <h1 className="md:hidden text-2xl text-center w-full mb-12 font-semibold">
                    Get Started
                  </h1>
                  {/* <span className="text-sm md:text-[20px] lg:text-[24px] font-medium text-white">
                    Connect for convenience!
                  </span>{" "} */}
                  Don’t miss out—grow your business by joining Gefmart today!
                </p>
              </div>
              <div
                className={`mt-12 md:mt-2 absolute md:static bottom-0 ${styles.otpContainer}`}
              >
                <div className="md:grid md:first-letter:grid place-content-center bg-white max-w-[500px] xm:max-w-[1000px] p-3 pb-10 md:p-[36px] lg:p-[40px] rounded-[20px] text-black">
                  <div>
                    <p className="mb-2 ml-10">Phone no</p>
                    <div className="flex ">
                      <p className="flex justify-center items-center h-[50px] w-[50px] bg-[#F5F5F5] rounded-[10px] focus:outline-none text-center text-slate-500">
                        +91
                      </p>
                      <div
                        className={`ml-3 flex bg-[#F5F5F5] rounded-[10px] ${styles.numberInput}`}
                      >
                        <input
                          className={`h-[50px] text-slate-500 w-[235px] xm:w-[250px] lg:w-[360px] bg-[#F5F5F5]  rounded-[10px] focus:outline-none px-4 mr-2`}
                          placeholder="Enter phone number"
                          type="tel"
                          maxLength={10}
                          minLength={10}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <div
                          className={`${styles.sendOtpTick} h-[50px] w-[46px] bg-[#00BF63]  cursor-pointer rounded-[10px] text-center flex justify-center items-center`}
                          style={{
                            opacity: phoneNumber.length === 10 ? 1 : 0.3,
                          }}
                        >
                          <TiTick
                            className="text-3xl text-[#FAFAFA]"
                            onClick={sendOtp}
                          />
                        </div>
                      </div>
                    </div>
                    {error.error === "number" && (
                      <p className="text-red-400 text-xs">{error.message}</p>
                    )}
                  </div>
                  <p className="text-center my-2 my-5">
                    Didn't recieve OTP?{" "}
                    <span className="text-[#00BF63]">
                      {" "}
                      {timer === 0 ? (
                        <span
                          className="underline cursor-pointer"
                          onClick={sendOtp}
                        >
                          Resend
                        </span>
                      ) : (
                        `Resend ${timer}s`
                      )}
                    </span>
                  </p>
                  <div className="flex flex-col gap-3 md:gap-0 md:flex-row justify-between items-center">
                    <p>OTP</p>
                    <div
                      className={`flex justify-between w-1/2 ${styles.otpInput}`}
                    >
                      {[...Array(4)].map((_, index) => (
                        <input
                          key={index}
                          ref={(el) => (inputs.current[index] = el)}
                          className={`w-[45px] h-[45px] lg:h-[50px] lg:w-[50px] bg-[#F5F5F5] rounded-[10px] focus:outline-none text-center ${
                            focusedIndex === index
                              ? "border-2 border-[#00BF63]"
                              : ""
                          }`}
                          type="tel"
                          maxLength={1}
                          onChange={(e) => handleChange(index, e)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          onFocus={() => setFocusedIndex(index)}
                          onBlur={() => setFocusedIndex(-1)}
                        />
                      ))}
                    </div>
                    <button
                      className="h-[44px] w-[100px]  lg:h-[54px] lg:w-[133px] bg-[#013220] text-white rounded-[10px]"
                      onClick={verifyOtp}
                    >
                      Sign In
                    </button>
                  </div>
                  {/* <div className="flex flex-col gap-3 md:gap-0 md:flex-row justify-between items-center">
                     <p>
                      Don't have an account?{" "}
                      <span className="underline text-[#00BF63]">Sign Up</span>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
