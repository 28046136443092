import axios from "axios";
import { Modal, Select, message } from "antd";
import { Edit } from "@mui/icons-material";
import { useContext, useState } from "react";

import styles from "./home.module.css";
import { UserStates } from "../../context/UserContext";
import { getUserDetailsUrl } from "../../apis/constants";
import dummyUserImg from "../../assets/user-dummy.png";
import { isValidUrl } from "../../constants/constants";

export default function EditUserModal({ show, setShow }) {
  const { user, setUser, accessToken } = useContext(UserStates);

  const userId = localStorage.getItem("userId");

  // const [user, setUser] = useState(null);
  const [profilePic, setProfilePic] = useState(
    localStorage.getItem("profile-pic")
  );

  const handleEditClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          setProfilePic(dataUrl); // Update state with the new image
          updateUserDetails(file);
          localStorage.setItem("profile-pic", dataUrl); // Optionally save to localStorage
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  };

  const changeValue = (e) => {
    console.log("e.target.name", e.target.name);
    console.log("e.target.value", e.target.value);

    const nameParts = e.target.name.split("."); // Split on dot
    if (nameParts[0] === "address" && nameParts.length === 2) {
      setUser({
        ...user,
        address: {
          ...user?.address,
          [nameParts[1]]: e.target.value, // Update specific nested key
        },
      });
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  // get user details
  const getUserDetails = () => {
    const config = {
      method: "get",
      url: `${getUserDetailsUrl}${userId}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setUser(res.data.data);
        setProfilePic(res.data.data.image);
        localStorage.setItem("user-name", res.data.data.name);
        localStorage.setItem("profile-pic", res.data.data.image);
      })
      .catch((err) => err);
  };

  // update user details
  const updateUserDetails = (image) => {
    const { number, ...filteredUser } = user;

    let form = new FormData();
    form.append("file", image);
    // Map over filteredUser and append each key-value pair to FormData
    Object.entries(filteredUser).forEach(([key, value]) => {
      if (key !== "address") {
        form.append(key, value);
      }
    });

    const config = {
      method: "patch",
      url: `${getUserDetailsUrl}${userId}`,
      data: image !== undefined ? form : filteredUser,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        message.success("Profile updated successfully");
        setUser(res.data.data);
        setProfilePic(res.data.data.image);
        localStorage.setItem("user-name", res.data.data.name);
        localStorage.setItem("profile-pic", res.data.data.image);
        setShow(false);
      })
      .catch((err) => err);
  };

  return (
    <Modal
      open={show}
      onClose={() => setShow(false)}
      onCancel={() => setShow(false)}
      className={styles.modal}
      okText="Save"
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      style={{ width: "700px" }}
    >
      <p style={{ fontSize: "20px" }}>Edit Profile</p>
      <div>
        <div className={styles.modalImageContainer}>
          <img src={isValidUrl(profilePic) ? profilePic : dummyUserImg} />
          <div
            className={styles.imgEditIcon}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }} // Ensure the icon looks clickable
            title="Edit Profile Picture" // Optional tooltip
          >
            <Edit />
          </div>
        </div>
        <div className={styles.modalInputs}>
          <div className={styles.inputContainer}>
            <label>Name</label>
            <input
              name="name"
              placeholder="Enter your name"
              value={user?.name}
              onChange={(e) => changeValue(e)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label>Place</label>
            <input
              name="address.place"
              placeholder="Enter your shop's place"
              value={user?.address?.place}
              onChange={(e) => changeValue(e)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label>Business Name</label>
            <input
              name="businessName"
              placeholder="Enter your business' name"
              value={user?.businessName || user?.business_name}
              onChange={(e) => changeValue(e)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label>GST Number</label>
            <input
              name="gst"
              placeholder="Enter your GST Number"
              maxLength={15}
              value={user?.gst}
              onChange={(e) => changeValue(e)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label>Alternative Number</label>
            <input
              name="alternativeNumber"
              type="tel"
              maxLength={10}
              placeholder="Enter your name"
              value={user?.alternativeNumber || user?.alternative_number}
              onChange={(e) => changeValue(e)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label>Are you a?</label>
            <Select
              value={user?.type}
              onChange={(e) => setUser({ ...user, type: e })}
            >
              <Select.Option value={5}>Retailer</Select.Option>
              <Select.Option value={6}>Distributor</Select.Option>
              <Select.Option value={4}>Channel Partner Gold</Select.Option>
              <Select.Option value={7}>Channel Partner Diamond</Select.Option>
            </Select>
          </div>

          <button onClick={updateUserDetails}>Save</button>
        </div>
      </div>
    </Modal>
  );
}
